import { Component } from 'preact'
import Page from "./page";
import { connect } from 'react-redux';

class Set extends Component {

	constructor(props) {
		super(props);
	}

	render = () => {

		const children = this.props.children.filter(child => child.parent === this.props.id);
		const stackedPage = children.filter(child => child.content.page_type=='page').length > 1
		
		return  <div data-container="set" data-id={this.props.id}>{
			children.map((child, index) => {
				if(child.content.page_type === "set") {
					return <ConnectedSet key={child.content.id} id={child.content.id} children={this.props.children} hashPurl={this.props.hashPurl} contentPad={this.props.contentPad} />
				} else {
					return <Page
						key={child.content.id}
						id={child.content.id}
						position={child.position}
						hashPurl={this.props.hashPurl}
						contentPad={this.props.contentPad}
						stackedPage={stackedPage} 
						snap_pages={this.props.snap_pages}
						snap_align={this.props.snap_align}
						snap_margin={this.props.snap_margin}
					/>
				}
			})
		}</div>
	}

}

function mapReduxStateToProps(state, ownProps) { 

	const page_design_options = state.sets.byId[ownProps.id]?.page_design_options;

	return {
		snap_pages:page_design_options?.snap_pages ?? false,
		snap_align:page_design_options?.snap_align ?? 'top',
		snap_margin:page_design_options?.snap_margin ?? '0rem',
	}
}

const ConnectedSet = connect(
	mapReduxStateToProps,
)(Set);

export default ConnectedSet
